<header class="header container-fluid">
    <div class="row">
        <div class="col-lg-3 col-sm-7 col-xs-9">
            <div class="logo">
                <a href="#" routerLink="/">
                    <img src="assets/img/logo/streetco.svg" alt="" />
                </a>
            </div>
        </div>
        <nav class="col-lg-9 menu">
            <ul>
                <li>
                    <a i18n="@@MENU_APP"  href="#" routerLink="/app" mat-button>Nos Applications</a>
                    <button mat-icon-button color="accent" class="header-menu-icon-button" aria-label="" [matMenuTriggerFor]="appMenu">
                        <mat-icon>expand_more</mat-icon>
                    </button>
                </li>
                <li>
                    <a i18n="@@MENU_OFFER" href="#" routerLink="/{{ I18N_URL.offer }}" mat-button>Nos Offres</a>
                    <button mat-icon-button color="accent" class="header-menu-icon-button" aria-label="" [matMenuTriggerFor]="offerMenu">
                        <mat-icon>expand_more</mat-icon>
                    </button>
                </li>
                <li>
                    <a i18n="@@MENU_MISSION" href="#" routerLink="/mission" mat-button>Notre mission</a>
                </li>
                <li>
                    <a i18n="@@MENU_CONTACT" href="#" routerLink="/contact" mat-raised-button color="primary">Nous contacter</a>
                </li>
            </ul>
        </nav>
        <div class="col-sm-5 col-xs-3 menu-responsive">
            <button mat-icon-button [matMenuTriggerFor]="responsiveMenu" aria-label="">
                <mat-icon>menu</mat-icon>
            </button>
        </div>
    </div>
</header>

<mat-menu #responsiveMenu="matMenu" class="mat-menu-custom">
    <button mat-menu-item [matMenuTriggerFor]="appMenu">
        <span i18n="@@MENU_RESPONSIVE_APP">Nos Applications</span>
    </button>
    <button mat-menu-item [matMenuTriggerFor]="offerMenu">
        <span i18n="@@MENU_RESPONSIVE_OFFER">Nos Offres</span>
    </button>
    <button mat-menu-item routerLink="/mission">
        <span i18n="@@MENU_RESPONSIVE_MISSION">Notre mission</span>
    </button>
    <button mat-menu-item routerLink="/contact">
        <span i18n="@@MENU_RESPONSIVE_CONTACT">Nous contacter</span>
    </button>
</mat-menu>

<mat-menu #appMenu="matMenu" xPosition="before" class="mat-menu-custom">
    <button mat-menu-item routerLink="/app/streetco">
        <div class="sub-menu-item">
            <div class="icon">
                <img src="assets/img/hedgehog/photo.svg" alt="" />
            </div>
            <span>StreetCo</span>
        </div>
    </button>
    <button mat-menu-item>
        <div class="sub-menu-item" routerLink="/app/streetnav">
            <div class="icon">
                <img class="walk" src="assets/img/hedgehog/walk.svg" alt="" />
            </div>
            <span>StreetNav</span>
        </div>
    </button>
</mat-menu>

<mat-menu #offerMenu="matMenu" xPosition="before" class="mat-menu-custom large">
    <button mat-menu-item routerLink="/{{ I18N_URL.offer }}/challenge">
        <div class="sub-menu-item">
            <div class="icon">
                <img class="walk" src="assets/img/hedgehog/crown.svg" alt="" />
            </div>
            <span i18n="@@MENU_OFFER_SUB_CHALLENGE">Pour les entreprises ...</span>
        </div>
    </button>
    <button mat-menu-item>
        <div class="sub-menu-item" routerLink="/{{ I18N_URL.offer }}/smartcity">
            <div class="icon">
                <img src="assets/img/hedgehog/heart.svg" alt="" />
            </div>
            <span i18n="@@MENU_OFFER_SUB_SMARTCITY">Pour les collectivités ...</span>
        </div>
    </button>
</mat-menu>

<main class="main-content">
    <router-outlet></router-outlet>
</main>

<footer class="footer pt-13 pb-13 accent-bg c-xs-tc">
    <div class="container">
        <div class="row">
            <div class="col-sm-3 col-xs-12">
                <div class="logo">
                    <img src="assets/img/logo/streetco_black.svg" alt="" />
                </div>
                <ul class="infos c-xs-mt-min">
                    <li>
                        <mat-icon aria-hidden="true" fontIcon="location_on"></mat-icon>
                        <span class="mat-body-1">Paris - France</span>
                    </li>
                    <li>
                        <mat-icon aria-hidden="true" fontIcon="mail"></mat-icon>
                        <a href="#" routerLink="/contact">
                            <span i18n="@@FOOTER_INFOS_CONTACT" class="mat-body-1">Nous contacter</span>
                        </a>
                    </li>
                    <li class="lang">
                        <a href="https://street-co.com/fr/">
                            <img src="assets/img/lang/fr.png" alt="" />
                        </a>
                        <a href="https://street-co.com/en/">
                            <img src="assets/img/lang/en.png" alt="" />
                        </a>
                    </li>
                </ul>
            </div>
            <div class="col-sm-5 col-xs-12 c-xs-mt-med">
                <p i18n="@@FOOTER_MENU_LINKS" class="mat-subtitle-2">Conditions et mentions légales</p>
                <ul class="links">
                    <li>
                        <a i18n="@@FOOTER_MENU_LEGAL_NOTICE" href="#" routerLink="/{{I18N_URL.legal_notice}}" class="mat-body-1">Mentions légales</a>
                    </li>
                    <li>
                        <a i18n="@@FOOTER_MENU_PRIVACY_POLICY" href="#" routerLink="/{{I18N_URL.privacy_policy}}" class="mat-body-1">Politique de Confidentialité</a>
                    </li>
                </ul>
            </div>
            <div class="col-sm-4 col-xs-12 c-xs-mt-med">
                <p i18n="@@FOOTER_NEWSLETTER_TITLE" class="mat-subtitle-2">Recevez notre newsletter</p>
                <mat-form-field class="sub-newsletter">
                    <mat-label i18n="@@FOOTER_NEWSLETTER_LABEL">S'inscrire à la newsletter</mat-label>
                    <input matInput disabled  type="text">
                    <button matSuffix mat-icon-button aria-label="" (click)="openNewsletter()">
                        <mat-icon>navigate_next</mat-icon>
                    </button>
                </mat-form-field>
            </div>
        </div>
        <div class="row between-sm bottom-sm pt-12 c-xs-reverse">
            <div class="col-sm-3 col-xs-12">
                <p class="c-xs-tc">©Streetco - 2022</p>
            </div>
            <div class="col-sm-7 col-xs-12 c-xs-mb-med">
                <div class="download flex-end">
                    <app-comp-download app="co"></app-comp-download>
                </div>
            </div>
            <div class="col-sm-2 col-xs-12 c-xs-mb-med">
                <ul class="socials">
                    <li>
                        <a href="https://twitter.com/streetcoapp" target="_blank">
                            <img src="assets/img/socials/twitter.svg" />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/streetcoapp" target="_blank">
                            <img src="assets/img/socials/instagram.svg" />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.facebook.com/StreetcoApp" target="_blank">
                            <img src="assets/img/socials/facebook.svg" />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</footer>