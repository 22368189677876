<section>
    <div class="container">

        <ng-container *ngIf="!htmlContent">
            <div class="progress-spinner-container">
                <div class="progress-spinner">
                    <div></div><div></div><div></div><div></div><div></div><div></div>
                    <div></div><div></div><div></div><div></div><div></div><div></div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="htmlContent">
            <div class="htmlContent" [innerHTML]="htmlContent"></div>
        </ng-container>

    </div>
</section>
