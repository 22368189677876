import { Injectable, Inject } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Injectable({
   providedIn: 'root'
})
export class SeoService {

    constructor(
        @Inject(DOCUMENT) private doc: any,
        private title: Title,
        private meta: Meta
    ) { }

    setPageTitle(title: string): void {
        this.title.setTitle(title);
        this.meta.updateTag({ name: 'title', content: title });
    }

    setPageDescription(description: string): void {
        this.meta.updateTag({ name: 'description', content: description });
    }

    createLinkForCanonicalURL(): void {
        let linkCanonical: Array<HTMLLinkElement> = this.doc.querySelectorAll('[rel="canonical"]');

        if (linkCanonical.length == 0) {
            // Create and append <link> canonical if not set
            const link: HTMLLinkElement = this.doc.createElement('link');
            link.setAttribute('rel', 'canonical');
            this.doc.head.appendChild(link);
            link.setAttribute('href', this.doc.URL);
        } else {
            // Update existing <link> canonical
            linkCanonical[0].setAttribute('href', this.doc.URL);
        }
    }
}
