import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { CommonModule, DOCUMENT } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { RouterOutlet } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input'; 
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

import { LocaleData } from './_datas/locale.data';
import { environment } from 'src/environments/environment';

import { DownloadComponent } from './_components/download/download.component';
import { DATA_META_OPENGRAPH, DATA_META_TWITTER } from './_datas/socials.data';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterLink, RouterOutlet, MatDialogModule, MatButtonModule, MatIconModule, MatMenuModule, MatInputModule, DownloadComponent],
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  private I18N_META: any = LocaleData.meta();
  I18N_URL: any = LocaleData.pageUrl();
  private DATA_META_OPENGRAPH: any = DATA_META_OPENGRAPH;
  private DATA_META_TWITTER: any = DATA_META_TWITTER;
  constructor(
    @Inject(DOCUMENT) private doc: any,
    public router: Router,
    private renderer: Renderer2,
    private meta: Meta,
    public dialog: MatDialog
  ) { 
    this.setTags();
    if (environment.production) {
      this.addGoogleAnalyticsScript();
      this.setGoogleAnalyticsRoutes();
    }
  }
  private setTags(): void {
    this.renderer.setAttribute(this.doc.documentElement, 'lang', environment.locale);

    /* TITLE & DESC TAGS */
    this.meta.updateTag({ name: 'title', content: this.I18N_META.social.title });
    this.meta.addTag({ name: 'description', content: this.I18N_META.social.description });

    /* OPEN GRAPH */
    this.meta.addTag({ property: 'og:title', content: this.I18N_META.social.title });
    this.meta.addTag({ property: 'og:description', content: this.I18N_META.social.description });
    this.meta.addTag({ property: 'og:type', content: this.DATA_META_OPENGRAPH.type });
    this.meta.addTag({ property: 'og:image', content: this.DATA_META_OPENGRAPH.image });
    this.meta.addTag({ property: 'og:url', content: environment.url_root.current });
    this.meta.addTag({ property: 'og:video', content: this.DATA_META_OPENGRAPH.video });
    this.meta.addTag({ property: 'og:locale', content: ( environment.locale == 'fr' ? 'fr_FR' : 'en_US' ) });
    this.meta.addTag({ property: 'og:site_name', content: this.DATA_META_OPENGRAPH.site_name });

    /* TWITTER */
    this.meta.addTag({ name: 'twitter:title', content: this.I18N_META.social.title });
    this.meta.addTag({ name: 'twitter:description', content: this.I18N_META.social.description });
    this.meta.addTag({ name: 'twitter:image', content: this.DATA_META_TWITTER.image });
    this.meta.addTag({ name: 'twitter:site', content: this.DATA_META_TWITTER.site });
    this.meta.addTag({ name: 'twitter:creator', content: this.DATA_META_TWITTER.creator });
    this.meta.addTag({ name: 'twitter:card', content: this.DATA_META_TWITTER.card });

    /* ALERNATE LANGS */
    const linkLangFr = this.renderer.createElement('link');
    this.renderer.setAttribute(linkLangFr, 'rel', 'alternate');
    this.renderer.setAttribute(linkLangFr, 'hreflang', 'fr');
    this.renderer.setAttribute(linkLangFr, 'href', environment.url_root.fr);
    this.renderer.appendChild(this.doc.head, linkLangFr);
    const linkLangEn = this.renderer.createElement('link');
    this.renderer.setAttribute(linkLangEn, 'rel', 'alternate');
    this.renderer.setAttribute(linkLangEn, 'hreflang', 'en');
    this.renderer.setAttribute(linkLangEn, 'href', environment.url_root.en);
    this.renderer.appendChild(this.doc.head, linkLangEn);
  }
  private addGoogleAnalyticsScript(): void {
    const scriptTagManager = this.renderer.createElement('script');
    scriptTagManager.defer = true;
    scriptTagManager.src = "https://www.googletagmanager.com/gtag/js?id=G-NS1P92HH6P";
    this.doc.head.appendChild(scriptTagManager);
    const scriptGtag = this.renderer.createElement('script');
    scriptGtag.innerHTML = `function gtag(){dataLayer.push(arguments)}window.dataLayer=window.dataLayer||[],gtag("js",new Date),gtag("config","G-NS1P92HH6P");`;
    this.doc.head.appendChild(scriptGtag);
  }
  private setGoogleAnalyticsRoutes(): void {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'G-NS1P92HH6P', { 'page_path': event.urlAfterRedirects });
      }
    });
  }
  openNewsletter(): void {
    const dialogRef = this.dialog.open(SubscribeToNewsletterDialog, { panelClass: 'newsletter-modal' });
    dialogRef.afterClosed().subscribe(() => {
      console.log('The dialog was closed');
    });
  }
}


@Component({
  selector: 'subscribe-to-newsletter-dialog',
  template: `
    <iframe width="540" height="305" src="https://545cd4ae.sibforms.com/serve/MUIFAC6ozgkgayMPWN3BSXoRVFIgtqgN65PJeYUKJPi4jBrY-tZ3EWEANkxYI6Xn9bT_gWYtVrclL_ev58fhlXCGDZR34YxJ5CQ5HYvuF5F_Q22DRCHbMQ9M6FZD3fZNdLGv1lX7K1NHqOgHLXKgMUgsZzmrPtIqkdvfUkJukVsUPHZeRJMGv3Ui4sfNTOackjyftZ_o6HmnugW9" frameborder="0" scrolling="auto" allowfullscreen style="display: block;margin-left: auto;margin-right: auto;max-width: 100%;"></iframe>
  `,
  imports: [CommonModule],
  standalone: true,
})
export class SubscribeToNewsletterDialog { }