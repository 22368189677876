export const environment = {
    locale: 'fr', 
    production: true,
    url_root: {
        current: 'https://street-co.com/',
        fr: 'https://street-co.com/',
        en: 'https://street-co.com/en/'
    },
    challenge_jo_url: 'https://challenge-jo.street-co.com/',
    challenge_seeph_url: 'https://challenge.street-co.com/seeph',
    tmp_inclusion_cards_storage_url: 'https://streetcoservicesstorage.blob.core.windows.net/website-inclusion-cards/'
};
