import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SOCIALS_DATA } from 'src/app/_datas/socials.data';

@Component({
  selector: 'app-comp-download',
  standalone: true,
  imports: [CommonModule],
  template: `
    <button type="button" class="download ios" (click)="navigateToStore('ios')">
        <span class="icon">
            <img src="/assets/img/socials/apple.svg" alt="" />
        </span>
        <span class="text">
            <span i18n="@@COMPONENT_DOWNLOAD_IOS_1" class="min">Télécharger dans</span>
            <span i18n="@@COMPONENT_DOWNLOAD_IOS_2" class="max">l'App Store</span>
        </span>
    </button>
    <button type="button" class="download android" (click)="navigateToStore('and')">
        <span class="icon">
          <img src="/assets/img/socials/google-play.svg" alt="" />
        </span>
        <span class="text">
            <span i18n="@@COMPONENT_DOWNLOAD_AND_1" class="min">disponible sur</span>
            <span i18n="@@COMPONENT_DOWNLOAD_AND_2" class="max">Google Play</span>
        </span>
    </button>
  `,
  styleUrls: ['download.component.scss']
})
export class DownloadComponent {
  @Input() app: string = "co";
  navigateToStore(platform: string) {
    let link = "";
    if (platform === 'ios') {
      if (this.app == 'nav') {
        link = SOCIALS_DATA.nav.app_store;
      } else {
        link = SOCIALS_DATA.co.app_store;
      }
    }
    if (platform === 'and') {
      if (this.app == 'nav') {
        link = SOCIALS_DATA.nav.google_play;
      } else {
        link = SOCIALS_DATA.co.google_play;
      }
    }
    window.open(link, '_blank');
  }
}
