export const LOCALE_DYNAMIC_EN = {
    meta: {
        title: "The app that helps people with reduced mobility get around",
        social: {
            title: "Streetco: Solutions for inclusive and accessible mobility ",
            description: "Welcome to Streetco, the solution for everyone to benefit from simple, safe and autonomous pedestrian mobility."
        },
    },
    pages: {
        title: {
            landing: "Streetco: The solution for inclusive and accessible mobility ",
            app: "Applications to make the world more accessible ",
            app_streetco: "Streetco: the app to make an impact on urban accessibility",
            app_streetnav: "The pedestrian GPS for all mobility needs ",
            offer: "Find out how you can contribute to a more accessible world ",
            offer_challenge: "Challenge - Rally your employees and raise their awareness.",
            offer_smartcity: "Smart City - Make your city accessible to all.",
            mission: "Our mission: to make mobility accessible to all.",
            contact: "Contact - Streetco",
            legal_notice: "Terms & Conditions - Streetco",
            privacy_policy: "Privacy policy - Streetco",
            not_found: "Page not found - Streetco",
            tmp_inclusion_cards: "Streetco launches the Inclusion Cards campaign",
        },
        description: {
            landing: "Welcome to Streetco, the solution for mapping accessibility and guiding people with reduced mobility..",
            app: "Our two applications, Streetco and StreetNav, offer data collection and real-time navigation solutions to make it easier for people with reduced mobility to get around.",
            app_streetco: "Find out about obstacles and identify accessible streets as you go about your daily life to help millions of people get around more easily.",
            app_streetnav: "The mobile application that generates indoor, outdoor and multimodal itineraries to get around easily and with peace of mind, whatever your mobility.",
            offer: "Streetco offers a range of services to meet your accessibility and disability needs.",
            offer_challenge: "An innovative, simple and fun way to raise awareness in a concrete way. Unite your teams around a meaningful action.",
            offer_smartcity: "At Streetco, we're building a barrier-free world for more accessible cities. For the most vulnerable and the least mobile.",
            mission: "At Streetco, we believe in a future where cities are accessible to all, where pedestrian movement is comfortable, safe and free of obstacles.",
            contact: "Any question? Contact us!",
            legal_notice: "Consult our legal notices ",
            privacy_policy: "Consult our privacy policy",
            not_found: "The requested page was not found ",
            tmp_inclusion_cards: "Streetco launches its “Inclusion Cards” campaign, which travels the length and breadth of France, flying the flag for accessibility.",
        },
    },
    url: {
        offer: "offer",
        privacy_policy: "privacy-policy",
        legal_notice: "legal-notice",
        tmp_inclusion_cards: "inclusion-cards",
    }
}