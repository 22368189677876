import { Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LOCALE_DYNAMIC_EN, LOCALE_DYNAMIC_FR } from 'src/locale/dynamic/_index';

import { ContentComponent } from './content/content.component';

export const routes: Routes = [
    {
        path: '', loadComponent: () => import('./landing/landing.component'),
    },
    {
        path: 'app', loadComponent: () => import('./app/app.component'),
        children: [
            {
                path: '', loadComponent: () => import('./app/main/main.component'),
            },
            { 
                path: 'streetco', loadComponent: () => import('./app/co/co.component') 
            },
            { 
                path: 'streetnav', loadComponent: () => import('./app/nav/nav.component') 
            },
        ],
    },
    {
        path: 
            environment.locale === 'fr' ? LOCALE_DYNAMIC_FR.url.offer : LOCALE_DYNAMIC_EN.url.offer,
            loadComponent: () => import('./offer/offer.component')
        ,
        children: [
            {
                path: '', loadComponent: () => import('./offer/main/main.component'),
            },
            { 
                path: 'challenge', loadComponent: () => import('./offer/challenge/challenge.component') 
            },
            { 
                path: 'smartcity', loadComponent: () => import('./offer/smartcity/smartcity.component') 
            },
        ],
    },
    {
        path: 'mission', loadComponent: () => import('./mission/mission.component'),
    },
    {
        path: 'contact', loadComponent: () => import('./contact/contact.component'),
    },
    { 
        path : environment.locale === 'fr' ? LOCALE_DYNAMIC_FR.url.legal_notice : LOCALE_DYNAMIC_EN.url.legal_notice,
        component: ContentComponent
    },
    { 
        path : environment.locale === 'fr' ? LOCALE_DYNAMIC_FR.url.privacy_policy : LOCALE_DYNAMIC_EN.url.privacy_policy,
        component: ContentComponent
    },
    { 
        path : environment.locale === 'fr' ? LOCALE_DYNAMIC_FR.url.tmp_inclusion_cards : LOCALE_DYNAMIC_EN.url.tmp_inclusion_cards,
        loadComponent: () => import('./tmp/inclusion-cards/inclusion-cards.component')
    },
    { 
        path : '404', loadComponent: () => import('./notfound/notfound.component'),
    },
    { 
        path : '**', redirectTo: '/404'
    }
];
